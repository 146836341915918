import React, {ReactNode, useState} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {Button} from "reactstrap";
import ApproveTOAccountModal from "../modals/ApproveTOAccountModal";
import DeclineTOAccountModal from "../modals/DeclineTOAccountModal";
import {secondsToShortDate} from "../../utils/formatters";
import {UserRequest} from "client";

interface IProps {
	data: Array<UserRequest>;
	onDoneAction(): void;
}

const TOApprovalTable: React.FC<IProps> = (props: IProps) => {

	const {data} = props;

	function makeReviewButtons(cell: string, row: any): ReactNode {
		return (<ReviewTOAccountButtons account={row} onDone={props.onDoneAction}/>);
	}

	return (
		<BootstrapTable
			bootstrap4={true}
			striped={false}
			hover={true}
			condensed={true}
			bordered={true}
			wrapperClasses="table-responsive"
			rowStyle={{whiteSpace: "nowrap"}}
			noDataIndication={data === undefined ? "Loading..." : "No Users"}
			defaultSorted={[{dataField: "email", order: "asc"}]}
			data={data ? data : []}
			keyField="_id"
			columns={[
				{
					dataField: "name",
					text: "Name",
					sort: true,
					formatter: (cell: string, row: UserRequest) => row.user.firstName + " " + row.user.lastName,
				},
				{
					dataField: "user[phoneNumber]",
					text: "Phone Number",
					sort: true,
				},
				{
					dataField: "user[email]",
					text: "Email",
					sort: true,
				},
				{
					dataField: "created",
					text: "Created",
					sort: true,
					formatter: secondsToShortDate,
				},
				{
					dataField: "actions",
					text: "Approve or Decline",
					formatter: makeReviewButtons,
				},
			]}
		/>
	);
};

interface IReviewTOAccountButtonsProps {
	account: UserRequest;
	onDone(): void;
}

const ReviewTOAccountButtons: React.FC<IReviewTOAccountButtonsProps> = (props: IReviewTOAccountButtonsProps) => {

	const [confirmApproveOpen, setConfirmApproveOpen] = useState(false);
	const [confirmDeclineOpen, setConfirmDeclineOpen] = useState(false);

	function toggleApprove(): void {
		setConfirmApproveOpen(!confirmApproveOpen);
	}

	function toggleDecline(): void {
		setConfirmDeclineOpen(!confirmDeclineOpen);
	}

	function done(): void {
		setConfirmApproveOpen(false);
		setConfirmDeclineOpen(false);
		props.onDone();
	}

	return (
		<React.Fragment>
			<ApproveTOAccountModal isOpen={confirmApproveOpen} account={props.account} onClose={toggleApprove} onDone={done}/>
			<DeclineTOAccountModal isOpen={confirmDeclineOpen} account={props.account} onClose={toggleDecline} onDone={done}/>

			<div className="d-flex">
				<Button color="success" onClick={toggleApprove} className="mr-3">
					Approve
				</Button>

				<Button color="danger" onClick={toggleDecline} className="mr-3">
					Decline
				</Button>
			</div>
		</React.Fragment>
	);
};

export default TOApprovalTable;
