import React, {ReactNode, useState} from "react";
import {GolfCourse, Tournament, TournamentWithActive} from "client";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import {secondsToShortDate} from "../../utils/formatters";
import BootstrapTable from "react-bootstrap-table-next";
import {golfCourseTypeLookup} from "../../utils/golfCourseTypeLookup";
import GolfCourseInput from "../golfCourseViewer/GolfCourseInput";
import EnableSearchModal from "../golfCourseViewer/EnableSearchModal";
import DisableSearchModal from "../golfCourseViewer/DisableSearchModal";
import paginationFactory from 'react-bootstrap-table2-paginator';
import EnableTournamentModal from "../toggleTournamentModals/EnableTournamentModal";
import DisableTournamentModal from "../toggleTournamentModals/DisableTournamentModal";
import moment from "moment";

interface IProps {
	data: Array<TournamentWithActive>;

	onDoneAction(): Promise<void>;
}

const customTotal = (from, to, size) => (
	<p className="react-bootstrap-table-pagination-total ml-3 mb-4 d-inline-block">
		Showing {from} to {to} of {size} Results
	</p>
);

const options = {
	paginationSize: 20,
	pageStartIndex: 1,
	alwaysShowAllBtns: true, // Always show next and previous button
	withFirstAndLast: true, // Hide the going to First and Last page button
	// hideSizePerPage: true, // Hide the sizePerPage dropdown always
	// hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
	firstPageText: 'First',
	prePageText: 'Back',
	nextPageText: 'Next',
	lastPageText: 'Last',
	nextPageTitle: 'First page',
	prePageTitle: 'Pre page',
	firstPageTitle: 'Next page',
	lastPageTitle: 'Last page',
	showTotal: true,
	paginationTotalRenderer: customTotal,
	disablePageTitle: true,
	sizePerPageList: [{
		text: '5', value: 5
	}, {
		text: '10', value: 10
	}, {
		text: '15', value: 15
	}, {
		text: '20', value: 20
	}, {
		text: '30', value: 30
	}, {
		text: '50', value: 50
	}, {
		text: '100', value: 100
	}] // A numeric array is also available. the purpose of above example is custom the text
};


const AdminTournamentManagementTable: React.FC<IProps> = (props: IProps) => {

	const {data} = props;

	function makeButtons(cell: string, row: any): ReactNode {
		return (<TournamentManagementButtons tournament={row} onDone={props.onDoneAction}/>);
	}

	return (
		<BootstrapTable
			bootstrap4={true}
			striped={false}
			hover={true}
			condensed={true}
			bordered={true}
			wrapperClasses="table-responsive"
			rowStyle={{whiteSpace: "nowrap"}}
			noDataIndication={data === undefined ? "Loading..." : "No Tournaments"}
			defaultSorted={[{dataField: "email", order: "asc"}]}
			data={data ? data : []}
			keyField="_id"
			pagination={paginationFactory(options)}
			columns={[
				{
					dataField: "tournament[created]",
					text: "Created",
					sort: true,
					formatter: secondsToShortDate,
				},
				{
					dataField: "tournament[name]",
					text: "Tournament Name",
					sort: true,
				},
				{
					dataField: "tournament[tournamentOrganizer][email]",
					text: "Tournament Organizer",
					sort: true,
				},
				{
					dataField: "tournament[startTime]",
					text: "Start Time",
					sort: true,
					formatter: (cell) => moment(cell).format("MMM DD YYYY h:mm A")
				},
				{
					dataField: "isActive",
					text: "Happening Now",
					sort: true,
					formatter: (cell) => {
						return (
							<p className="text-center mb-0">{cell ? (<b>Yes</b>) : "No"}</p>
						);
					}
				},
				{
					dataField: "tournament[disabled]",
					text: "Enabled",
					sort: true,
					formatter: (cell) => {
						return (
							<p className="text-center mb-0">{!cell ? (<b>Yes</b>) : "No"}</p>
						);
					}
				},
				{
					dataField: "actions",
					text: "Enable / Disable",
					formatter: makeButtons,
				},
			]}
		/>
	);
};

interface ITournamentManagementButtonsProps {
	tournament: TournamentWithActive;

	onDone(): Promise<void>;
}

const TournamentManagementButtons: React.FC<ITournamentManagementButtonsProps> = (props: ITournamentManagementButtonsProps) => {

	const [enableModalOpen, setEnableModalOpen] = useState(false);
	const [disableModalOpen, setDisableModalOpen] = useState(false);

	function toggleEnable(): void {
		setEnableModalOpen(!enableModalOpen);
	}

	function toggleDisable(): void {
		setDisableModalOpen(!disableModalOpen);
	}

	function done(): void {
		setEnableModalOpen(false);
		setDisableModalOpen(false);
		props.onDone().then().catch();
	}

	return (
		<React.Fragment>
			<EnableTournamentModal tournament={props.tournament} open={enableModalOpen} toggle={toggleEnable} onDone={done}/>
			<DisableTournamentModal tournament={props.tournament} open={disableModalOpen} toggle={toggleDisable} onDone={done}/>

			<div className="d-flex flex-column justify-content-center align-items-center">
				{props.tournament.tournament.disabled ? (
                    <Button color="success" size="sm" onClick={toggleEnable} className="mr-3">
                        Enable
                    </Button>
				) : (
					<React.Fragment>
						<Button color="danger" size="sm" onClick={toggleDisable} className="mr-3 d-block" disabled={props.tournament.isActive}>
							Disable
						</Button>
						{props.tournament.isActive && (
							<div className="d-block mt-1">
								<p className="mb-0 text-center text-muted font-italic">Can't disable tournament<br/>while it is happening</p>
							</div>
						)}
					</React.Fragment>
				)}
			</div>
		</React.Fragment>
	);
};

export default AdminTournamentManagementTable;
