import React, {useEffect, useState} from "react";
import {GolfCourse, GolfCourseApi} from "client";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Container} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import GolfCourseManagementTable from "../components/tables/GolfCourseManagementTable";

interface IProps {
	token?: string;
	dispatch?: any;
}

const ManageGolfCourses: React.FC<IProps> = (props: IProps) => {

	const {token} = props;
	const [data, setData] = useState<Array<GolfCourse>>(undefined);

	useEffect(() => {
		readCourses().then().catch();
	}, []);

	async function readCourses(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new GolfCourseApi(getConfig(token)).getAllGolfCourses();
			setData(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());

	}

	return (
		<div className="px-3">
			<Container className="my-5">
				<div className="mb-5">
					<h1>
						Golf Course Management
					</h1>
					<p>
						On this page you can see a list of all the currently golf courses in the system, and enable or disable them from appearing in searches.
					</p>
				</div>
			</Container>

			<GolfCourseManagementTable data={data} onDoneAction={readCourses}/>
		</div>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		token: store.metaStore.token,
	}
})(ManageGolfCourses);
