import React from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {GolfCourse, GolfCourseApi} from "client";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";

interface IProps {
	token?: string;
	dispatch?: any;
	golfCourse: GolfCourse;
	open: boolean;
	toggle(): void;
	onDone(): void;
}

const DisableSearchModal: React.FC<IProps> = (props: IProps) => {

	const {token, golfCourse, open} = props;

	async function confirmDisable(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new GolfCourseApi(getConfig(token)).toggleSeachableGolfCourse({iDBody: {id: golfCourse._id}});

			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<Modal
			isOpen={open}
			centered={true}
			fade={true}
			toggle={props.toggle}
		>
			<ModalHeader toggle={props.toggle}>Disable Searching for the "{props.golfCourse.golfClub}" Golf course?</ModalHeader>
			<ModalBody>
				<p>
					By clicking the "Disable Search" button below, this golf course, "{props.golfCourse.golfClub}" will stop appearing in searches by Tournament Organizers.
				</p>
			</ModalBody>
			<ModalFooter>
				<Button color="link" className="mr-3" onClick={props.toggle}>Cancel</Button>

				<Button color="success" onClick={confirmDisable}>Disable Search</Button>
			</ModalFooter>
		</Modal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(DisableSearchModal)
