import React from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {GolfCourse, GolfCourseApi, Tournament, TournamentApi, TournamentWithActive} from "client";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";

interface IProps {
	token?: string;
	dispatch?: any;
	tournament: TournamentWithActive;
	open: boolean;

	toggle(): void;

	onDone(): void;
}

const DisableTournamentModal: React.FC<IProps> = (props: IProps) => {

	const {token, tournament, open} = props;

	async function confirmDisable(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new TournamentApi(getConfig(token)).toggleDisableTournament({iDBody: {id: tournament.tournament._id}});

			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<Modal
			isOpen={open}
			centered={true}
			fade={true}
			toggle={props.toggle}
		>
			<ModalHeader toggle={props.toggle}>Disable this tournament?</ModalHeader>
			<ModalBody>
				<p>
					Are you sure you want to disable the <b>{props.tournament.tournament.name}</b> Tournament?
				</p>
				<p>
					By clicking the "Disable Tournament" button below, this tournament will become inaccessible, and the TO to whom it belongs will not see it in their TO portal anymore.
				</p>
			</ModalBody>
			<ModalFooter>
				<Button color="link" className="mr-3" onClick={props.toggle}>Cancel</Button>

				<Button color="success" onClick={confirmDisable}>Disable Tournament</Button>
			</ModalFooter>
		</Modal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(DisableTournamentModal)
