import {GolfCourseType} from "client";

export function golfCourseTypeLookup(type: GolfCourseType): string {
	switch (type) {
		case GolfCourseType.NONHANDICAPNONGENDERED:
			return "Non-Handicap, Non-Gendered";
		case GolfCourseType.NONHANDICAPGENDERED:
			return "Non-Handicap, Gendered";
		case GolfCourseType.HANDICAPNONGENDERED:
			return "Handicap, Non-Gendered";
		case GolfCourseType.HANDICAPGENDERED:
			return "Handicap, Gendered";
		case GolfCourseType.HANDICAPGENDEREDSCRAMBLE:
			return "Handicap, Gendered, Scramble";
	}
}
