import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {GolfCourse, GolfCourseApi, TournamentApi, TournamentWithActive} from "client";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import {Container} from "reactstrap";
import AdminTournamentManagementTable from "../components/tables/AdminTournamentManagementTable";

interface IProps {
	token?: string;
	dispatch?: any;
}

const ManageTournaments: React.FC<IProps> = (props: IProps) => {

	const {token} = props;
	const [data, setData] = useState<Array<TournamentWithActive>>(undefined);

	useEffect(() => {
		readTournaments().then().catch();
	}, []);

	async function readTournaments(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new TournamentApi(getConfig(token)).getAllTournaments({isActive: false});
			setData(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());

	}

	return (
		<div className="px-3">
			<Container className="my-5">
				<div className="mb-5">
					<h1>
						Admin Tournament Management
					</h1>
					<p>
						On this page you can see a list of all the tournaments in the system, and enable or disable them from.
					</p>
				</div>
			</Container>

			<AdminTournamentManagementTable data={data} onDoneAction={readTournaments}/>
		</div>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(ManageTournaments);
