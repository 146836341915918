import React from "react";
import {connect} from "react-redux";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {AdminApi, User, UserRequest} from "client";
import getConfig from "../../utils/getConfig";

interface IProps {
	token?: string;
	dispatch?: any;
	isOpen: boolean;
	account: UserRequest,
	onClose(): void;
	onDone(): void;
}

const DeclineTOAccountModal: React.FC<IProps> = (props: IProps) => {

	const {token, isOpen, account} = props;

	async function declineTO(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new AdminApi(getConfig(token)).deleteUserRequest({iDBody: {id: account._id}});
			props.dispatch(decrementLoading());
			props.onDone();
		} catch (e) {
			props.dispatch(decrementLoading());
			props.dispatch(addError(e));
		}
	}

	return (
		<Modal
			isOpen={isOpen}
			fade={true}
			centered={true}
			toggle={props.onClose}
		>
			<ModalHeader toggle={props.onClose}>Decline Tournament Organizer</ModalHeader>

			<ModalBody>
				<p>
					Declining this Tournament Organizer account will not grant them access to the Tournament Organizer portal.
				</p>
			</ModalBody>

			<ModalFooter>
				<Button color="link" onClick={props.onClose} className="mr-3">
					Cancel
				</Button>

				<Button color="danger" onClick={declineTO}>
					Confirm, Decline
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		token: store.metaStore.token,
	}
})(DeclineTOAccountModal);
