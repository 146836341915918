import React, {ReactNode, useState} from "react";
import {GolfCourse} from "client";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import {secondsToShortDate} from "../../utils/formatters";
import BootstrapTable from "react-bootstrap-table-next";
import {golfCourseTypeLookup} from "../../utils/golfCourseTypeLookup";
import GolfCourseInput from "../golfCourseViewer/GolfCourseInput";
import EnableSearchModal from "../golfCourseViewer/EnableSearchModal";
import DisableSearchModal from "../golfCourseViewer/DisableSearchModal";
import paginationFactory from 'react-bootstrap-table2-paginator';

interface IProps {
	data: Array<GolfCourse>;

	onDoneAction(): Promise<void>;
}

const customTotal = (from, to, size) => (
	<p className="react-bootstrap-table-pagination-total ml-3 mb-4 d-inline-block">
		Showing {from} to {to} of {size} Results
	</p>
);

const options = {
	paginationSize: 15,
	pageStartIndex: 1,
	alwaysShowAllBtns: true, // Always show next and previous button
	withFirstAndLast: true, // Hide the going to First and Last page button
	// hideSizePerPage: true, // Hide the sizePerPage dropdown always
	// hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
	firstPageText: 'First',
	prePageText: 'Back',
	nextPageText: 'Next',
	lastPageText: 'Last',
	nextPageTitle: 'First page',
	prePageTitle: 'Pre page',
	firstPageTitle: 'Next page',
	lastPageTitle: 'Last page',
	showTotal: true,
	paginationTotalRenderer: customTotal,
	disablePageTitle: true,
	sizePerPageList: [{
		text: '5', value: 5
	}, {
		text: '10', value: 10
	}, {
		text: '15', value: 15
	}, {
		text: '20', value: 20
	}, {
		text: '30', value: 30
	}, {
		text: '50', value: 50
	}, {
		text: '100', value: 100
	}] // A numeric array is also available. the purpose of above example is custom the text
};


const GolfCourseManagementTable: React.FC<IProps> = (props: IProps) => {

	const {data} = props;

	function makeButtons(cell: string, row: any): ReactNode {
		return (<GolfCourseManagementButtons golfCourse={row} onDone={props.onDoneAction}/>);
	}

	function makeViewParButtons(cell: string, row: any): ReactNode {
		return (<ViewParButtons golfCourse={row}/>);
	}

	return (
		<BootstrapTable
			bootstrap4={true}
			striped={false}
			hover={true}
			condensed={true}
			bordered={true}
			wrapperClasses="table-responsive"
			rowStyle={{whiteSpace: "nowrap"}}
			noDataIndication={data === undefined ? "Loading..." : "No Golf Courses"}
			defaultSorted={[{dataField: "email", order: "asc"}]}
			data={data ? data : []}
			keyField="_id"
			pagination={paginationFactory(options)}
			columns={[
				{
					dataField: "golfClub",
					text: "Golf Club",
					sort: true,
				},
				{
					dataField: "golfCourseType",
					text: "Course Type",
					sort: true,
					formatter: golfCourseTypeLookup,
				},
				{
					dataField: "address",
					text: "Address",
					sort: false,
					formatter: (cell, row: GolfCourse) => {
						return (
							<div>
								{(row.country || row.province) && (
									<p className="mb-1">{row.country ? <span
										className={row.province ? "mr-2" : ""}>{row.country}</span> : ""}{row.province ?
										<span>{row.province}</span> : ""}</p>
								)}
								<p className="mb-0">{row.address}</p>
							</div>
						);
					}
				},
				// {
				// 	dataField: "country",
				// 	text: "Country",
				// 	sort: true,
				// },
				// {
				// 	dataField: "province",
				// 	text: "Province",
				// 	sort: true,
				// },
				{
					dataField: "created",
					text: "Created",
					sort: true,
					formatter: secondsToShortDate,
				},
				{
					dataField: "source",
					text: "Created By",
					sort: true,
				},
				{
					dataField: "",
					text: "Slope Ratings",
					sort: false,
					formatter: makeSlopeRatingsCell,
				},
				{
					dataField: "",
					text: "Course Ratings",
					sort: false,
					formatter: makeCourseRatingsCell,
				},
				{
					dataField: "",
					text: "Pars/Handicaps",
					sort: false,
					formatter: makeViewParButtons,
				},
				{
					dataField: "searchable",
					text: "Visible in Searches",
					sort: true,
					formatter: (cell) => {
						return (
							<p className="text-center mb-0">{cell ? (<b>Yes</b>) : "No"}</p>
						);
					}
				},
				{
					dataField: "actions",
					text: "Toggle Search Visibility",
					formatter: makeButtons,
				},
			]}
		/>
	);
};

function makeSlopeRatingsCell(cell: any, course: GolfCourse): ReactNode {
	return (
		<div>
			{course.slopeRating && (<p className="mb-1">Slope Rating: <b>{course.slopeRating}</b></p>)}
			{course.maleSlopeRating && (<p className="mb-1">Male Slope Rating: <b>{course.maleSlopeRating}</b></p>)}
			{course.femaleSlopeRating && (
				<p className="mb-1">Female Slope Rating: <b>{course.femaleSlopeRating}</b></p>)}
		</div>
	);
}

function makeCourseRatingsCell(cell: any, course: GolfCourse): ReactNode {
	return (
		<div>
			{course.courseRating && (<p className="mb-1">Course Rating: <b>{course.courseRating}</b></p>)}
			{course.maleCourseRating && (<p className="mb-1">Male Course Rating: <b>{course.maleCourseRating}</b></p>)}
			{course.femaleCourseRating && (
				<p className="mb-1">Female Course Rating: <b>{course.femaleCourseRating}</b></p>)}
		</div>
	);
}

interface IViewParButtonsProps {
	golfCourse: GolfCourse;
}

const ViewParButtons: React.FC<IViewParButtonsProps> = (props: IViewParButtonsProps) => {

	const [showPars, setShowPars] = useState(false);

	function toggleModal(): void {
		setShowPars(!showPars);
	}

	return (
		<React.Fragment>
			<Modal
				isOpen={showPars}
				centered={true}
				fade={true}
				toggle={toggleModal}
				className="create-courses-modal"
			>
				<ModalHeader toggle={toggleModal}>{props.golfCourse.golfClub}</ModalHeader>
				<ModalBody>
					<div className="d-flex overflow-x-scroll">
						<GolfCourseInput
							golfCourseType={props.golfCourse.golfCourseType}
							holes={props.golfCourse.holes}
							editPar={false}
							showPar={true}
							editHandicap={false}
							showHandicap={true}
							selectable={false}
						/>
					</div>
				</ModalBody>
			</Modal>

			<div className="d-flex justify-content-center">
				<Button color="info" size="sm" onClick={toggleModal}>View</Button>
			</div>
		</React.Fragment>
	);
};

interface IGolfCourseManagementButtonsProps {
	golfCourse: GolfCourse;

	onDone(): Promise<void>;
}

const GolfCourseManagementButtons: React.FC<IGolfCourseManagementButtonsProps> = (props: IGolfCourseManagementButtonsProps) => {

	const [enableSearchForCourseOpen, setEnableSearchForCourseOpen] = useState(false);
	const [disableSearchForCourseOpen, setDisableSearchForCourseOpen] = useState(false);

	function toggleEnable(): void {
		setEnableSearchForCourseOpen(!enableSearchForCourseOpen);
	}

	function toggleDisable(): void {
		setDisableSearchForCourseOpen(!disableSearchForCourseOpen);
	}

	function done(): void {
		setEnableSearchForCourseOpen(false);
		setDisableSearchForCourseOpen(false);
		props.onDone().then().catch();
	}

	return (
		<React.Fragment>
			<EnableSearchModal golfCourse={props.golfCourse} open={enableSearchForCourseOpen} toggle={toggleEnable}
			                   onDone={done}/>
			<DisableSearchModal golfCourse={props.golfCourse} open={disableSearchForCourseOpen} toggle={toggleDisable}
			                    onDone={done}/>

			<div className="d-flex justify-content-center">
				{props.golfCourse.searchable ? (
					<Button color="danger" size="sm" onClick={toggleDisable} className="mr-3">
						Disable
					</Button>
				) : (
					<Button color="success" size="sm" onClick={toggleEnable} className="mr-3">
						Enable
					</Button>
				)}
			</div>
		</React.Fragment>
	);
};

export default GolfCourseManagementTable;
