import React from "react";
import {Button, Modal, ModalFooter, ModalHeader} from "reactstrap";
import {Asset} from "client";

interface IProps {
	isOpen: boolean;
	asset: Asset;
	onClose(madeChange: boolean): void;
}

const ViewAssetModal: React.FC<IProps> = (props: IProps) => {

	const {isOpen, asset} = props;

	function closeHelper(): void {
		props.onClose(false);
	}

	return (
		<Modal
			isOpen={isOpen}
			centered={true}
			toggle={closeHelper}
		>
			<ModalHeader toggle={closeHelper}>Viewing: {asset.name}</ModalHeader>

			<img
				src={asset.url}
				style={{maxWidth: "100%", maxHeight: "80vh", objectFit: "contain"}}
			/>

			<ModalFooter>
				<Button color="primary" onClick={closeHelper}>
					Close
				</Button>
			</ModalFooter>
		</Modal>
	)
};

export default ViewAssetModal;
