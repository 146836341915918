import React from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {GolfCourse, GolfCourseApi, Tournament, TournamentApi, TournamentWithActive} from "client";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";

interface IProps {
	token?: string;
	dispatch?: any;
	tournament: TournamentWithActive;
	open: boolean;
	toggle(): void;
	onDone(): void;
}

const EnableTournamentModal: React.FC<IProps> = (props: IProps) => {

	const {token, tournament, open} = props;

	async function confirmEnable(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new TournamentApi(getConfig(token)).toggleDisableTournament({iDBody: {id: tournament.tournament._id}});

			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<Modal
			isOpen={open}
			centered={true}
			fade={true}
			toggle={props.toggle}
		>
			<ModalHeader toggle={props.toggle}>Enable this tournament?</ModalHeader>
			<ModalBody>
				<p>
					Are you sure you want to enable the <b>{props.tournament.tournament.name}</b> Tournament?
				</p>
				<p>
					By clicking the "Enable Tournament" button below, this tournament will become accessible again, and the TO who owns it will be able to see it again.
				</p>
			</ModalBody>
			<ModalFooter>
				<Button color="link" className="mr-3" onClick={props.toggle}>Cancel</Button>

				<Button color="success" onClick={confirmEnable}>Enable Tournament</Button>
			</ModalFooter>
		</Modal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(EnableTournamentModal)
