import React from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Spinner} from "reactstrap";

interface IProps {
	loadingIncrement?: number;
}

const LoadingManager: React.FC<IProps> = (props: IProps) => {

	if (!props.loadingIncrement || props.loadingIncrement < 1) {
		return null;
	}

	return (
		<div
			className="d-flex justify-content-center align-items-center"
			style={{
				width: "100vw",
				height: "100vh",
				position: "fixed",
				zIndex: 50000,
				backgroundColor: "rgba(0, 0, 0, 0.4)",
			}}
		>
			<Spinner color="light"/>
		</div>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		loadingIncrement: store.metaStore.loadingIncrement,
	}
})(LoadingManager);
